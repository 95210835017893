<template>
  <div class="content content--home">
    <h1 class="title title--big title--theme title--indent">{{title}}</h1>
    <app-cells v-if="!userAdmin && !userDirector" position="start">
      <app-button
        v-if="status === 1"
        @click="getUpdateStatus(2)"
        theme="accept"
        size="small">
        Начать выполнения задачи
      </app-button>
      <app-button
        v-else-if="status === 2"
        @click="getUpdateStatus(3)"
        theme="accept"
        size="small">
        Завершить задачу
      </app-button>
      <app-button
        v-else-if="status === 3"
        @click="getUpdateStatus(1)"
        theme="accept"
        size="small">
        Начать заново
      </app-button>
      <router-link :to="{ name: 'edit-task' }" class="btn btn--primary btn--sm">
        Редактировать задачу
      </router-link>
      <app-button
        v-if="userAdmin || userDirector === 'admin'"
        @click="deleteTask"
        theme="delete"
        size="small">
          Удалить задачу
      </app-button>
    </app-cells>
    <app-cells v-else position="start">
      <app-button
        data-status=""
        @click="getUpdateStatus(2)"
        theme="accept"
        size="small"
        >
        Начать выполнения задачи
      </app-button>
      <app-button
        @click="getUpdateStatus(3)"
        theme="accept"
        size="small">
        Завершить задачу
      </app-button>
      <app-button
        @click="getUpdateStatus(1)"
        theme="accept"
        size="small"
      >
        Начать заново
      </app-button>
      <router-link :to="{ name: 'edit-task' }" class="btn btn--primary btn--sm">
        Редактировать задачу
      </router-link>
      <app-button
        v-if="userAdmin || userDirector === 'admin'"
        @click="deleteTask"
        theme="delete"
        size="small">
        Удалить задачу
      </app-button>
    </app-cells>
    <transition name="fade" mode="out-in">
      <div class="view-cols">
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>ID</b>
          </div>
          <div class="view-cols__part">
        <span>
          {{ items.id }}
        </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Наименование</b>
          </div>
          <div class="view-cols__part">
        <span>
          {{ items.name }}
        </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Описание</b>
          </div>
          <div class="view-cols__part">
        <span v-html="items.desc"></span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Дата начала</b>
          </div>
          <div class="view-cols__part">
        <span>
          {{ items.start_date }}
        </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Крайний срок</b>
          </div>
          <div class="view-cols__part">
        <span>
          {{ items.deadline }}
        </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Статус задачи</b>
          </div>
          <div class="view-cols__part">
        <span>
          {{ items.status.name }}
        </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Последние изменение</b>
          </div>
          <div class="view-cols__part">
        <span>
          {{ items.updated_at }}
        </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Участники</b>
          </div>
          <div class="view-cols__part">
            <span>
              <div v-if="items.executor && items.executor.length" class="view-cols__item">
                  <p class="view-cols__item-title">Иполнитель: </p>
                  <span v-for="prop in items.executor">
                    {{prop.full_name}}
                  </span>
              </div>
              <div v-if="items.co_executor && items.co_executor.length" class="view-cols__item">
                <p class="view-cols__item-title">Соисполнитель: </p>
                <span v-for="prop in items.co_executor">
                  {{prop.full_name}}
                </span>
              </div>

              <div v-if="items.observer && items.observer.length" class="view-cols__item">
                <p class="view-cols__item-title">Наблюдатель: </p>
                <span v-for="prop in items.observer">
                  {{prop.full_name}}
                </span>
              </div>
            </span>
          </div>
        </div>
        <div class="view-cols__row">
          <div class="view-cols__part">
            <b>Файлы</b>
          </div>
          <div class="view-cols__part">
        <span>
<!--          {{ items.docs_obj }}-->
        </span>
          </div>
        </div>
      </div>
    </transition>
    <form @submit.prevent="" class="">
      <div class="title title--indent">Чек лист</div>
      <app-form-group
        v-for="checkbox in todoItems"
        class="form-group--checkbox"
      >
        <label class="checkbox checkbox--position">
          <span class="checkbox__text">{{ checkbox.name }}</span>
          <input
            type="checkbox"
            :checked="checkbox.is_checked"
            @change="sendFormCheckedTodo(checkbox)"
          />
          <span class="checkbox__checkmark"></span>
        </label>
        <app-button
          v-if="can_edit"
          @click="deleteTodo(checkbox)"
          class="btn--ident-left"
          size="basket"
          title="Удалить"
        ></app-button>
      </app-form-group>
    </form>
    <form @submit.prevent="onCheckFormTodo">
      <app-form-group label-for="">
        <app-input
          id="todo"
          v-model="formTodo.name"
          :error="$v.formTodo.name.$error"
          @change.native="$v.formTodo.name.$touch()"
        />
        <template #error>
          <div v-if="$v.formTodo.name.$dirty && !$v.formTodo.name.required">Поле не может быть пустым</div>
        </template>
      </app-form-group>
      <app-cells>
        <app-button :disabled="$v.formTodo.name.$error" ref="submit">Создать</app-button>
      </app-cells>
    </form>
    <form @submit.prevent="onCheckFormComment">
      <app-form-group label-for="comment" label="Комментарий">
        <app-textarea
          v-model="formComment"
          id="comment"
          :error="$v.formComment.$error"
          @change.native="$v.formComment.$touch()"
          placeholder="Добавить комментарий"
        >
        </app-textarea>
        <template #error>
          <div v-if="$v.formComment.$dirty && !$v.formComment.required">Поле не может быть пустым</div>
        </template>
      </app-form-group>
      <app-cells>
        <app-button :disabled="$v.formComment.$error" ref="submit">Отправить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'MyTask',
  data() {
    return {
      items: {},
      title: '',
      status: null,
      userDirector: this.$store.state.meProfile.is_director,
      userAdmin: this.$store.state.meProfile.is_admin,
      formComment: null,
      formTodo: {
        name: null
      },
      todo: null,
      todoItems: [],
      commentItems: [],
      can_edit: null,
      id: this.$route.params.id
    }
  },
  validations: {
    formComment: { required },
    formTodo: {
      name: { required }
    },
  },
  created() {
    this.getData();
    this.getTodo();
  },
  // updated() {
  //
  // },
  methods: {
    getData() {
      this.$store.dispatch('profile/task/GET_DATA', this.id)
        .then((response) => {
          this.title = response.data.name
          this.status = response.data.status
          this.can_edit = response.data.permissions.can_edit
          this.items = response.data
        })
    },
    deleteTask() {
      this.$store.dispatch('profile/task/DELETE_DATA', this.id)
      .then(() => {
        this.$router.push({ name: 'tasks-all'})

      })
    },
    getTodo() {
      this.$store.dispatch('todo/GET_DATA', this.id)
        .then((response) => {
          this.todoItems = response.data.results
        })
    },
    deleteTodo(item) {
      const {name , id } = item
      const params = {
        id: this.id,
        todo_id: id,
        params: {
          name: name,
        }
      }
      this.$store.dispatch('todo/DELETE_DATA',params)
        .then(() => {
          this.getTodo()
        })
    },
    onCheckFormTodo() {
      this.$v.formTodo.name.$touch()
      if (this.$v.formTodo.name.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendFormTodo()
      }
    },
    sendFormTodo() {
      const id = this.id;
      const name = this.formTodo;
      this.$store.dispatch('todo/POST_DATA', { id, name})
      .then(() => {
        this.getTodo()
      })
    },
    // normalizeFormTodo() {
    //   const { ...data } = this.formTodo
    //   return data
    // },
    onCheckFormComment() {
      this.$v.formComment.$touch()
      if (this.$v.formComment.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        // this.sendForm()
      }
    },
    sendFormCheckedTodo(item) {
      const {name , id, is_checked } = item
      const params = {
        id: this.id,
        todo_id: id,
        params: {
          name: name,
          is_checked: !is_checked
        }
      }
      this.$store.dispatch('todo/PUT_DATA', params)
        .then(() => {
          this.getTodo()
        })
    },
    getUpdateStatus(id) {
      const status = id
      const idsObj = {
        id: this.$route.params.id,
        params: {
          status
        }
      }
      this.$store.dispatch('profile/task/PATCH_DATA', idsObj)
        .then((response) => {
          this.getData()
        })
    }
  }
}
</script>
